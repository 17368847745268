import { usePublicRunStore } from '@/stores/public-run/public-run-checklist.store';
import { Run } from '@/types/run.type';
import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { RenderRunComponent } from '../runs/run-by-id.component';
import { RunProgressComponent } from '../runs/run-progress.component';

export default function PublicRunBuilderComponent() {
  // Variables

  // States
  const [run, setRun] = useState<Run | undefined>();
  const [isPreview, setIsPreview] = useState(true);

  // Store
  const [previewChecklist, checklist, publicRun, publicRunChecklist] =
    usePublicRunStore(
      s => [s.previewChecklist, s.checklist, s.publicRun, s.publicRunChecklist],
      shallow
    );

  useEffect(() => {
    if (!checklist) {
      setIsPreview(true);
      formRunObject(true);
    } else if (checklist && publicRun && publicRunChecklist) {
      setIsPreview(false);
      formRunObject(false);
    }
  }, [previewChecklist, checklist, publicRun, publicRunChecklist]);

  function formRunObject(isPreview: boolean) {
    if (isPreview && previewChecklist) {
      const runObject: Run = {
        id: '',
        name: previewChecklist?.title,
        checklist_version_id: previewChecklist?.checklist_version_id,
        checklist_version: previewChecklist?.checklist_version,
        run_checklist_id: '',
        run_checklists: []
      };

      setRun(runObject);
    } else if (checklist && publicRun && publicRunChecklist) {
      const runObject: Run = {
        id: publicRun?.id,
        name: publicRun?.name,
        checklist_version_id: publicRun?.checklist_version_id,
        checklist_version: {
          checklistByVersionChecklistId: checklist
        },
        run_checklist_id: '',
        run_checklists: publicRunChecklist
      };

      setRun(runObject);
    }
  }

  return (
    <>
      {/* Run progress */}
      <div className="fixed top-0 left-0 right-0 z-50">
        <RunProgressComponent />
      </div>

      <div className="max-w-[1440px] mx-auto w-full h-full overflow-auto pt-5">
        {/* Body */}
        {run && (
          <RenderRunComponent
            runId={run?.id as string}
            run={run}
            isPreview={isPreview}
            isPublic={true}
            isSingleResponse={false}
          />
        )}
      </div>
    </>
  );
}
