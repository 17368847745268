
/**
 * Get Twitter post content
 */
export function shareOnTwitter(title: string, url?: string, target?: boolean) {
    // Set current browser url
    if (!url) {
        url = window.location.href;
    }

    if (title && url) {
        const link = "https://twitter.com/intent/tweet?text=" + title + "&url=" + url;
        if (target) {
            window.open(link, "_self");
        } else {
            window.open(link, "_blank");
        }
    }
}

/**
 * Get Facebook post content
 */
export function shareOnFacebook(title: string, url?: string, target?: boolean) {
    // Set current browser url
    if (!url) {
        url = window.location.href;
    }

    if (url) {
        const link = "https://www.facebook.com/sharer.php?u=" + url;
        if (target) {
            window.open(link, "_self");
        } else {
            window.open(link, "_blank");
        }
    }
}

/**
 * Get Linkedin post content
 */
export function shareOnLinkedin(title: string, url?: string, target?: boolean, summary?: string) {
    // Set current browser url
    if (!url) {
        url = window.location.href;
    }

    if (url) {
        let link = "https://www.linkedin.com/sharing/share-offsite/?url=" + url;

        // Adding extra text
        if (summary) {
            link = link + "&summary=" + summary
        }

        if (target) {
            window.open(link, "_self");
        } else {
            window.open(link, "_blank");
        }
    }
}

/**
 * Get Whatsapp post content
 */
export function shareOnWhatsapp(title: string, url?: string, target?: boolean) {
    // Set current browser url
    if (!url) {
        url = window.location.href;
    }

    if (url) {
        const link = "https://web.whatsapp.com/send?text=" + url;
        if (target) {
            window.open(link, "_self");
        } else {
            window.open(link, "_blank");
        }
    }
}

/**
 * Get Telegram post content
 */
export function shareOnTelegram(title: string, url?: string, target?: boolean) {
    // Set current browser url
    if (!url) {
        url = window.location.href;
    }

    if (url) {
        const link = "https://telegram.me/share/url?url=" + url;
        if (target) {
            window.open(link, "_self");
        } else {
            window.open(link, "_blank");
        }
    }
}

/**
 * Get Facebook post content
 */
export function shareOnReddit(title: string, url?: string, target?: boolean) {
    // Set current browser url
    if (!url) {
        url = window.location.href;
    }

    if (url) {
        const link = "https://www.reddit.com/submit?url=" + url;
        if (target) {
            window.open(link, "_self");
        } else {
            window.open(link, "_blank");
        }
    }
}