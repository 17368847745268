import PublicRunComponent from '@/components/public-run/public-run.component';
import CommonConstants from '@/constants/common-constants';
import {
  decryptChecklistId,
  isValidUUID
} from '@/services/utils/utils.service';
import { Checklist } from '@/types/checklist.type';
import { MetaInfo } from '@/types/meta-info.type';
import { MyPage } from '@/types/my-page.type';
import { StartRunResponse } from '@/types/public-run.type';
import axios from 'axios';
import type { GetServerSideProps } from 'next';

export interface PublicRunPageProps {
  checklist?: Checklist;
  url?: string;
  responseData?: StartRunResponse
  metaInfo?: MetaInfo
}

const PublicRun: MyPage = (props: PublicRunPageProps) => {
  return <PublicRunComponent checklist={props?.checklist} url={props?.url} responseData={props?.responseData} metaInfo={props?.metaInfo} />;
};
export default PublicRun;
PublicRun.isNotProtected = true;

// Server side rendering
export const getServerSideProps = (async context => {
  const url = context.resolvedUrl;
  const checklistIdFromParams = context.params?.checklistId as string;
  let checklistId;

  // If the checklistId is already a UUID, then don't decode it
  if (isValidUUID(checklistIdFromParams)) {
    checklistId = checklistIdFromParams;
  } else {
    checklistId = decryptChecklistId(checklistIdFromParams);
  }

  // If checklistId is not found, just return
  if (!checklistId) {
    return { props: { url } };
  }

  // Fetch checklist data
  try {
    const res = await fetch(
      CommonConstants.API_PREFIX + '/public/run/get-checklist-by-id',
      {
        // Adding method type
        method: 'POST',

        // Adding body or contents to send
        body: JSON.stringify({
          checklistId: checklistId
        }),

        // Adding headers to the request
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      }
    );
    const data = await res.json();

    let checklist: Checklist | undefined = undefined;
    let metaInfo: MetaInfo | undefined = undefined;
    if (
      data &&
      data?.data &&
      data?.data?.checklist &&
      data?.data?.checklist?.checklist?.length > 0
    ) {
      checklist = data?.data?.checklist?.checklist[0] as Checklist;
    }
    
    if (data?.data?.metaInfo?.checklist_meta_data.length > 0) {
      metaInfo = data?.data?.metaInfo?.checklist_meta_data[0] as MetaInfo;
    }

    // Pass data to the page via props
    if (checklist) {
      // If checklist is found, initiate the anonymous run and fetch the response data
      let responseData: StartRunResponse | undefined = undefined;
      if (checklist?.id && context?.query?.anonymous) {
        const initialResponse = await axios.post(
          CommonConstants.API_PREFIX + '/public/run/anonymous/start',
          { checklistId: checklist.id }
        );

        responseData = initialResponse?.data?.data;

        return { props: { checklist, url, responseData, metaInfo } };
      } else {
        return { props: { checklist: checklist, url, metaInfo } };
      }
    } else {
      return { props: { url } };
    }
  } catch (error) {
    return { props: { url } };
  }
}) satisfies GetServerSideProps<PublicRunPageProps>;
