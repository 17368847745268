import { gql } from "@apollo/client";

export const GetPublicRunsByChecklistId = gql`
    query getPublicRunsByChecklistId($id: uuid!){
        public_runs(where: {checklist_id: {_eq: $id}}, order_by: {created_at: desc}) {
            id
            name
            created_at
            org_guest_user {
                full_name
                email
            }
            public_run_checklist {
                is_completed
                updated_at
                completed_at
            }
        }
    }
`;

export const GetRunsByChecklistId = gql`
  query getRunsByChecklistId($id: uuid!) {
    runs(where: {checklist_id: {_eq: $id}}, order_by: {created_at: desc}) {
      id
      name
      created_at
      due_at
      user {
        displayName
        email
      }
      run_checklist {
        is_completed
        updated_at
        completed_at
      }
    }
  }
`;