import { useRunChecklistStore } from '@/stores/run/run-checklist.store';
import { Checklist } from '@/types/checklist.type';
import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import ThankyouPopupComponent from './thankyou-popup.component';

interface PopupProps {
  checklistId: string;
  checklist: Checklist;
}

export default function ThankyouComponent({
  checklistId,
  checklist
}: PopupProps) {
  // Variables

  // States
  const [thankyouIsOpen, setThankyouIsOpen] = useState<boolean>(false);

  // Store
  const [runChecklistTree] = useRunChecklistStore(
    s => [s.runChecklistTree],
    shallow
  );

  useEffect(() => {
    // Once root checklist is completed, open thankyou popup
    if (runChecklistTree?.checklist?.is_completed === true) {
      setThankyouIsOpen(true);

      // Clear localstrage
      localStorage.removeItem(checklistId + '-session');
      localStorage.removeItem(checklistId + '-publicRun');
      localStorage.removeItem(checklistId + '-orgGuestUser');
    }
  }, [runChecklistTree]);

  return (
    <>
      {/* Thankyou popup */}
      {thankyouIsOpen && (
        <ThankyouPopupComponent
          isOpen={thankyouIsOpen}
          setIsOpen={setThankyouIsOpen}
          checklistId={checklistId as string}
          checklist={checklist}
        />
      )}
    </>
  );
}
