import {
  CLPrimaryCustomButton,
  CLPrimaryInput
} from '@/components/ui-controls';
import { LoadingSpinBlackCustom } from '@/components/utils/loading.component';
import CommonConstants from '@/constants/common-constants';
import { isValidEmail } from '@/services/utils/utils.service';
import { usePublicRunStore } from '@/stores/public-run/public-run-checklist.store';
import { Checklist } from '@/types/checklist.type';
import { StartRunResponse } from '@/types/public-run.type';
import { Dialog, Transition } from '@headlessui/react';
import { useUserData } from '@nhost/nextjs';
import axios, { AxiosError } from 'axios';
import Head from 'next/head';
import {
  Dispatch,
  Fragment,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { shallow } from 'zustand/shallow';

interface PopupProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  checklistId: string;
  checklist: Checklist;
}

declare global {
  interface Window {
    onloadTurnstileCallback: any;
    turnstile: any;
  }
}

export default function WelcomePopupComponent({
  isOpen,
  setIsOpen,
  checklistId,
  checklist
}: PopupProps) {
  // Variables

  // States
  const [fullName, setFullName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [turnstileToken, setTurnstileToken] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Store
  const [initialize] = usePublicRunStore(s => [s.initialize], shallow);

  // Hooks
  const userData = useUserData();

  useEffect(() => {
    window.onloadTurnstileCallback = () => {
      window.turnstile.render('#cl-turnstile-container', {
        sitekey: process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY,
        callback: (token: string) => {
          setTurnstileToken(token);
        }
      });
    };
  }, []);

  useEffect(() => {
    // Prefill the data
    if (userData) {
      if (userData?.email) {
        setEmail(userData?.email);
      }

      if (userData?.displayName) {
        setFullName(userData?.displayName);
      }
    }
  }, [userData]);

  function refreshTurnstileToken() {
    if (window.turnstile) {
      window.turnstile.reset('#cl-turnstile-container');
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      startRun;
    }
  };

  const startRun = async () => {
    // Reset the error
    setErrorMsg('');

    // Validate full name and email
    if (!fullName || !email) {
      setErrorMsg('Please enter Full name and Email');
      return;
    } else {
      // Validate full name
      const words = fullName.split(/\s+/);

     // // Check if the 'words' array is empty or if the first word in the array has less than 2 characters
     if (words.length < 1 || words[0].length < 2) {
      // If either condition is true, set an error message asking for a valid name
     setErrorMsg('Please enter a valid name');
     return;
   }

      // Validate email
      const isValidEmailFlag = isValidEmail(email);
      if (!isValidEmailFlag) {
        setErrorMsg('Please enter valid Email');
        return;
      }
    }

    if (!turnstileToken) {
      setErrorMsg(
        'Captcha verification is not loaded. Please reload and try again.'
      );
      return;
    }

    // Refresh the token always, since current token will be used in the following request
    refreshTurnstileToken();

    try {
      // Start processing
      setIsLoading(true);

      const payload = {
        // email: 'raja@blocksurvey.org',
        // fullName: 'Raja I',
        // checklistId: '333f4c8d-fe66-4082-9613-1df6144317d5'

        email: email,
        fullName: fullName,
        checklistId: checklistId
      };

      // Start the run
      const response = await axios.post(
        CommonConstants.API_PREFIX + '/public/run/start',
        payload,
        {
          headers: {
            authorization: 'Bearer ' + turnstileToken
          }
        }
      );

      if (response?.data?.data) {
        const startRunResponse = response?.data?.data as StartRunResponse;
        if (
          startRunResponse?.orgGuestUser &&
          startRunResponse?.checklist &&
          startRunResponse?.publicRun &&
          startRunResponse?.publicRunChecklist &&
          startRunResponse?.publicRunAccessToken
        ) {
          initialize(
            startRunResponse?.orgGuestUser,
            startRunResponse?.checklist,
            startRunResponse?.publicRun,
            startRunResponse?.publicRunChecklist,
            startRunResponse?.publicRunAccessToken
          );

          // Store the session in localstorage
          localStorage.setItem(
            checklistId + '-session',
            startRunResponse?.publicRunAccessToken
          );
          localStorage.setItem(
            checklistId + '-publicRun',
            JSON.stringify(startRunResponse?.publicRun)
          );
          localStorage.setItem(
            checklistId + '-orgGuestUser',
            JSON.stringify(startRunResponse?.orgGuestUser)
          );
        }
      }

      // Stop processing
      setIsLoading(false);

      // Hide the welcome popup
      setIsOpen(false);
    } catch (error) {
      // Stop processing
      setIsLoading(false);

      // Show error message
      if (error instanceof AxiosError) {
        setErrorMsg(error?.response?.data?.error?.message || error.message);
      } else {
        setErrorMsg('Something Went wrong. Please try again');
        throw error;
      }
    }
  };

  return (
    <>
      <Head>
        {/* Load turnstile captcha */}
        <script
          src="https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback"
          async
          defer
        ></script>
      </Head>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            // setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-6 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-lg bg-white p-4 text-left align-middle shadow-xl transition-all">
                  {/* Body */}
                  <div className="flex flex-col gap-6 sm:p-6 md:p-10 justify-center items-center">
                    <div className="text-center flex flex-col gap-2 items-center justify-center">
                      {/* Title */}
                      <h1 className="text-xl md:text-2xl lg:text-3xl md:w-[80%]">
                        {/* Where Do You Want The Checklist Delivered? */}
                        {checklist?.title}
                      </h1>

                      {/* Description */}
                      {checklist?.description && (
                        <p className="text-md md:text-lg md:w-[90%]">
                          {checklist?.description}
                        </p>
                      )}
                    </div>

                    {/* Input section */}
                    <div className="flex flex-col gap-4 md:w-[60%]">
                      <div className="flex flex-col gap-2">
                        <div>
                          {/* Full name */}
                          <CLPrimaryInput
                            id="full_name"
                            type="text"
                            label="Full name *"
                            placeholder="Jane Doe"
                            onKeyDown={handleKeyDown}
                            value={fullName}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setFullName(event.target.value);
                            }}
                          />
                        </div>

                        <div>
                          {/* Email */}
                          <CLPrimaryInput
                            id="email"
                            type="email"
                            label="Email *"
                            placeholder="jane@example.com"
                            onKeyDown={handleKeyDown}
                            value={email}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setEmail(event.target.value);
                            }}
                          />
                        </div>

                        {/* Turnstile container */}
                        <div id="cl-turnstile-container"></div>

                        {/* Error message */}
                        {errorMsg && (
                          <div className="mt-2 text-red-400">{errorMsg}</div>
                        )}
                      </div>

                      {/* Start */}
                      <CLPrimaryCustomButton
                        className="p-3 flex gap-2"
                        disabled={isLoading || !turnstileToken}
                        onClick={() => {
                          startRun();
                        }}
                      >
                        {!turnstileToken ? 'Loading...' : 'Start'}

                        {isLoading && (
                          <LoadingSpinBlackCustom className="w-4 h-4"></LoadingSpinBlackCustom>
                        )}
                      </CLPrimaryCustomButton>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
