import CommonConstants from '@/constants/common-constants';
import { PublicRunPageProps } from '@/pages/[checklistId]';
import { usePublicRunStore } from '@/stores/public-run/public-run-checklist.store';
import {
  OrgGuestUser,
  PublicRun,
  StartRunResponse
} from '@/types/public-run.type';
import axios from 'axios';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import MetaInfo from '../meta-info/meta-info.component';
import { LoadingSpinPopupWithTransparentBackground } from '../utils/loading.component';
import PublicRunBuilderComponent from './public-run-builder.component';
import ThankyouComponent from './thankyou/thankyou.component';
import WelcomePopupComponent from './welcome/welcome-popup.component';
import { decryptChecklistId } from '@/services/utils/utils.service';
import { Run } from '@/types/run.type';
import { RenderRunComponent } from '../runs/run-by-id.component';

export default function PublicRunComponent({
  checklist,
  url,
  responseData,
  metaInfo
}: PublicRunPageProps) {
  // Variables

  // Add a cache-busting parameter to the URL
  const cacheBustingUrl = `${url}?v=${new Date().getTime()}`;

  const checklistId = checklist?.id;
  const metaInformation = {
    title: (metaInfo?.title || checklist?.title || 'Untitled') + ' | Checklist.gg',
    metaTitle: (metaInfo?.title || checklist?.title || 'Untitled') + ' | Checklist.gg',
    description:
      ( metaInfo?.description || 'checklist.gg is an AI-driven checklists, processes and SOPs management tool designed to help organizations streamline and optimize their workflow. It allows users to create, assign, and track tasks and checklists in real-time, ensuring that all team members are on the same page and that tasks are completed efficiently and effectively.'),
    keywords:
      'checklist software, SOP software, checklist management, SOP management, process management, AI checklist, Checklist manifesto',
    url: cacheBustingUrl,
    image:
      metaInfo?.image_url || `https://app.checklist.gg/api/og?title=` + checklist?.title ||
      'Checklist.gg',
      favicon: metaInfo?.fav_icon_url || "/favicon.ico"
  };

  // States
   const [formedRun, setFormedRun] = useState<Run | undefined>();

  function checkAnonymousParam(url: any) {
    try {
        // Extract the query string from the URL
        const queryString = url.split('?')[1];
        
        // If there is no query string, return false
        if (!queryString) {
            return false;
        }
        
        // Parse the query string into key-value pairs
        const params = new URLSearchParams(queryString);
        
        // Check if 'anonymous' parameter exists and if its value is 'true'
        return params.get('anonymous') === 'true';
    } catch (error: any) {
        console.error('Error parsing URL:', error.message);
        return false;
    }
}

function checkRunIdParam(url: any) {
  try {
    // Extract the query string from the URL
    const queryString = url.split('?')[1];

    // If there is no query string, return false
    if (!queryString) {
      return false;
    }

    // Parse the query string into key-value pairs
    const params = new URLSearchParams(queryString);

    // Check if 'runId' parameter exists
    return params.get('runId') === 'true';
  } catch (error: any) {
    console.error('Error parsing URL:', error.message);
    return false;
  }
}

  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [welcomeIsOpen, setWelcomeIsOpen] = useState<boolean>(false);

  // Store
  const [setPreviewChecklist, initialize] = usePublicRunStore(
    s => [s.setPreviewChecklist, s.initialize],
    shallow
  );

  useEffect(() => {
    // If checklist previous state is found on localstorage, just resume it
    const session = localStorage.getItem(checklistId + '-session');
    const publicRunString = localStorage.getItem(checklistId + '-publicRun');
    const orgGuestUserString = localStorage.getItem(
      checklistId + '-orgGuestUser'
    );

    if (!session || !publicRunString || !orgGuestUserString) {
      // Clear the localstorage
      localStorage.removeItem(checklistId + '-session');
      localStorage.removeItem(checklistId + '-publicRun');
      localStorage.removeItem(checklistId + '-orgGuestUser');

      if (checkAnonymousParam(url)) {
        setWelcomeIsOpen(false);

        if (responseData) {
          const startRunResponse = responseData as StartRunResponse;
          if (
            startRunResponse?.orgGuestUser &&
            startRunResponse?.checklist &&
            startRunResponse?.publicRun &&
            startRunResponse?.publicRunChecklist &&
            startRunResponse?.publicRunAccessToken
          ) {
            initialize(
              startRunResponse?.orgGuestUser,
              startRunResponse?.checklist,
              startRunResponse?.publicRun,
              startRunResponse?.publicRunChecklist,
              startRunResponse?.publicRunAccessToken
            );

            // Store the session in localstorage
            localStorage.setItem(
              checklistId + '-session',
              startRunResponse?.publicRunAccessToken
            );
            localStorage.setItem(
              checklistId + '-publicRun',
              JSON.stringify(startRunResponse?.publicRun)
            );
            localStorage.setItem(
              checklistId + '-orgGuestUser',
              JSON.stringify(startRunResponse?.orgGuestUser)
            );
          }
        }
      } else {
        setWelcomeIsOpen(true);
      }
    } else {
      const publicRun: PublicRun = JSON.parse(publicRunString);
      const orgGuestUser: OrgGuestUser = JSON.parse(orgGuestUserString);

      // Resume publicRun
      resumePublicRun(publicRun, orgGuestUser, session);
    }

    // If runId is present in the URL, fetch the run response
    if (checkRunIdParam(url)) {
      const cleanUrl = url?.split('?')[0];
      const runId = decryptChecklistId(cleanUrl as string);
      
      //Response data after Checklist is completed
      if (!checklist && !responseData && url) {
      const fetchData = async () => {
        setIsLoading(true);
        const data = await fetchRunResponse(runId as string);
        setIsLoading(false);
        
        if (data && data?.data?.public_runs) {
          if (data?.data?.public_runs?.length > 0) {
            // Form run object
            formRunObject(data?.data.public_runs[0]);
          } else {
            // Run not found
          }
        }
      };
  
      fetchData();
    }
  }
  }, []);

  // Store checklist to preview
  useEffect(() => {
    if (checklist) {
      setPreviewChecklist(checklist);
    }
  }, [checklist]);

  async function resumePublicRun(
    publicRun: PublicRun,
    orgGuestUser: OrgGuestUser,
    session: string
  ) {
    if (
      session &&
      publicRun &&
      publicRun?.id &&
      orgGuestUser &&
      orgGuestUser.email &&
      orgGuestUser.full_name
    ) {
      try {
        // Start processing
        setIsLoading(true);

        try {
          const payload = {
            email: orgGuestUser.email,
            fullName: orgGuestUser?.full_name,
            checklistId: checklistId,
            runId: publicRun?.id
          };

          // Resume the run
          const response = await axios.post(
            CommonConstants.API_PREFIX + '/public/run/resume',
            payload,
            {
              headers: {
                authorization: 'Bearer ' + session
              }
            }
          );

          if (response?.data?.data) {
            const startRunResponse = response?.data?.data as StartRunResponse;
            if (
              startRunResponse?.orgGuestUser &&
              startRunResponse?.checklist &&
              startRunResponse?.publicRun &&
              startRunResponse?.publicRunChecklist &&
              startRunResponse?.publicRunAccessToken
            ) {
              initialize(
                startRunResponse?.orgGuestUser,
                startRunResponse?.checklist,
                startRunResponse?.publicRun,
                startRunResponse?.publicRunChecklist,
                startRunResponse?.publicRunAccessToken
              );

              // Store the session in localstorage
              localStorage.setItem(
                checklistId + '-session',
                startRunResponse?.publicRunAccessToken
              );
              localStorage.setItem(
                checklistId + '-publicRun',
                JSON.stringify(startRunResponse?.publicRun)
              );
              localStorage.setItem(
                checklistId + '-orgGuestUser',
                JSON.stringify(startRunResponse?.orgGuestUser)
              );
            }
          }
        } catch (error) {
          // Clear the localstorage
          localStorage.removeItem(checklistId + '-session');
          localStorage.removeItem(checklistId + '-publicRun');
          localStorage.removeItem(checklistId + '-orgGuestUser');

          // Open the welcome popup
          setWelcomeIsOpen(true);
        }

        // Stop processing
        setIsLoading(false);
      } catch (error) {
        // Stop processing
        setIsLoading(false);

        // Open welcome popup
        setWelcomeIsOpen(true);

        throw error;
      }
    } else {
      // Open welcome popup
      setWelcomeIsOpen(true);
    }
  }

  function formRunObject(singleRunWithRunChecklists: PublicRun) {
    const runObject: Run = {
      id: singleRunWithRunChecklists?.id,
      name: singleRunWithRunChecklists?.name,
      checklist_version_id: singleRunWithRunChecklists?.checklist_version_id,
      checklist_version: singleRunWithRunChecklists?.checklist_version,
      run_checklist_id: singleRunWithRunChecklists?.public_run_checklist_id,
      run_checklists: singleRunWithRunChecklists?.public_run_checklists || []
    };

    setFormedRun(runObject);
  }

  // If runId is present in the URL, fetch the run response
  if (checkRunIdParam(url) && formedRun && !checklist && !responseData && url) {
    const metaInfo = {
      title: (formedRun?.name || 'Untitled') + ' | Checklist.gg',
      metaTitle: (formedRun?.name || 'Untitled') + ' | Checklist.gg',
      description:
        'checklist.gg is an AI-driven checklists, processes and SOPs management tool designed to help organizations streamline and optimize their workflow. It allows users to create, assign, and track tasks and checklists in real-time, ensuring that all team members are on the same page and that tasks are completed efficiently and effectively.',
      keywords:
        'checklist software, SOP software, checklist management, SOP management, process management, AI checklist, Checklist manifesto',
      url: url,
      image:
        `https://app.checklist.gg/api/og?title=` + formedRun?.name ||
        'Checklist.gg'
    };
    
    return (
      <>
        {/* Header */}
        <Head>
          <title>{formedRun?.name  + ' | Checklist.gg'}</title>
          <MetaInfo metaInfo={metaInfo}></MetaInfo>
        </Head>

        {/* Title */}
        {formedRun?.name && (
            <div className="text-xl font-semibold line-clamp-1 mt-4 mb-0 mx-6">
            &nbsp;{formedRun?.name}
            </div>
        )}

        {formedRun && (
        <div className="rounded-lg overflow-hidden p-6">
          {/* Checklist Status */}
          <RenderRunComponent
            runId={formedRun?.id as string}
            run={formedRun}
            isPreview={true}
            isPublic={false}
            isSingleResponse={true}
          />
        </div>
      )}
      </>
    );
  }
  
  // Checklist not found or if it is draft
  if (!checkRunIdParam(url) && (!checklist || !checklist.checklist_version_id)) {
    return (
      <>
        <div className="h-screen flex items-center justify-center">
          <p className="text-xl md:text-4xl">Checklist does not exist!</p>
        </div>
      </>
    );
  }

  // Checklist is found
  return (
    <div>
      {/* Header */}
      <Head>
        <title>{checklist?.title}</title>
        <MetaInfo metaInfo={metaInformation}></MetaInfo>
      </Head>

      {/* Builder */}
      <PublicRunBuilderComponent />

      {/* Show loading */}
      {isLoading && <LoadingSpinPopupWithTransparentBackground isOpen={true} />}

      {/* Welcome */}
      {welcomeIsOpen && checklist && (
        <WelcomePopupComponent
          isOpen={welcomeIsOpen}
          setIsOpen={setWelcomeIsOpen}
          checklistId={checklistId as string}
          checklist={checklist}
        />
      )}

      {/* Thankyou */}
      {checklist &&
      <ThankyouComponent
        checklistId={checklistId as string}
        checklist={checklist}
      />}
    </div>
  );
}

async function fetchRunResponse(runId: string) {
  try {
    const res = await fetch(
  CommonConstants.API_PREFIX + '/public/run/fetch-run-response',
  {
    // Adding method type
    method: 'POST',

    // Adding body or contents to send
    body: JSON.stringify({
      runId: runId
    }),

    // Adding headers to the request
    headers: {
      'Content-type': 'application/json; charset=UTF-8'
    }
  }
    );

    const data = await res.json();
    return data
  } catch (error) {
    console.error('Error fetching run response:', error);
  }
}